<template>
  <div>
    <!-- For asbestos search -->
    <BaseModal
      ref="modal"
      :title="title"
      content-padding="tw-p-0"
      max-width="tw-max-w-7xl"
      @hide="onHide"
    >
      <div
        v-if="pdfUrl || dossierStatus === 'ready_to_send'"
        slot="sticky-heading"
        class="tw-grid tw-grid-flow-col tw-divide-x tw-text-center"
      >
        <button
          type="button"
          :class="[
            'tw-py-4 tw-m-0 hover:tw-underline',
            { 'tw-bg-gray-e9': activeTab !== 'form' }
          ]"
          @click="activeTab = 'form'"
        >
          <h3 class="tw-m-0">Dossier</h3>
        </button>
        <button
          v-if="pdfUrl"
          type="button"
          :class="[
            'tw-py-4 tw-m-0 hover:tw-underline',
            { 'tw-bg-gray-e9': activeTab !== 'preview' }
          ]"
          @click="activeTab = 'preview'"
        >
          <h3 class="tw-m-0">Preview</h3>
        </button>
        <button
          v-if="dossierStatus === 'ready_to_send'"
          type="button"
          :class="[
            'tw-py-4 tw-m-0 hover:tw-underline',
            { 'tw-bg-gray-e9': activeTab !== 'send' }
          ]"
          @click="activeTab = 'send'"
        >
          <h3 class="tw-m-0">Klaarzetten</h3>
        </button>
      </div>

      <div
        v-if="dossierHistory"
        class="tw-p-4 tw-border-b tw-border-gray-cc tw-flex tw-flex-wrap xl:tw-flex-nowrap tw-gap-x-3 tw-gap-y-8"
      >
        <div
          v-for="event in dossierHistory"
          :key="event.id"
          class="tw-text-xs tw-text-center tw-flex-grow"
        >
          <span
            v-if="event.created_on"
            :class="['tw-font-semibold tw-whitespace-nowrap', event.key === 'cancelled' ? 'tw-text-error' : '']"
          >
            {{ event.created_on | date-day }}
          </span>
          <span v-else class="tw-text-gray-cc tw-whitespace-nowrap">
            In behandeling
          </span>

          <div class="tw-mb-4 tw-text-center">
            <component :is="event.comment ? 'Tooltip' : 'div'">
              <i
                :class="[
                  'fas tw-p-1 tw-bg-white tw-rounded-full tw-translate-y-[50%] tw-mx-auto tw-text-sm',
                  ...(event.created_on
                    ? event.key === 'cancelled'
                      ? ['tw-text-error', event.comment ? 'fa-comments' : 'fa-times-circle']
                      : ['tw-text-success', event.comment ? 'fa-comments' : 'fa-check-circle']
                    : ['fa-clock tw-text-gray-cc']
                  )
                ]"
              />
              <template #popper>
                {{ event.comment }}
              </template>
            </component>

            <hr
              :class="[
                'tw--mx-2',
                event.created_on
                  ? event.key === 'cancelled' ? 'tw-border-error' : 'tw-border-success'
                  : 'tw-border-gray-e9'
              ]"
            />
          </div>

          <span :class="{ 'tw-text-gray-cc': !event.created_on, 'tw-text-error': event.key === 'cancelled' }">
            {{ event.status_display }}
          </span>
        </div>
      </div>

      <div class="tw-px-8 tw-py-6">
        <transition-group mode="out-in" name="fade">
          <EccaAssignmentForm
            v-show="activeTab === 'send'"
            v-if="dossierStatus === 'ready_to_send'"
            key="send"
            v-bind="{
              owners,
              property,
              dossier: values
            }"
            @assignment-created="hide"
            @reset-current-dossier="dossierResetted"
          />

          <div v-show="activeTab === 'preview' && pdfUrl" key="preview">
            <iframe
              id="pdf_frame"
              type="application/pdf"
              :src="pdfUrl"
              class="tw-w-full tw-h-full tw-min-h-[69vh]"
            />

            <div
              v-if="!values.form_file"
              class="tw-mt-4 tw-flex tw-justify-end tw-gap-4 tw-flex-wrap tw-items-end"
            >
              <!-- If file is already stored, don't store it again. -->
              <FormulateInput
                v-if="values.signature_method === 'digital'"
                type="button"
                :disabled="storingDocument"
                outer-class="tw-my-0"
                @click="storeGeneratedDocument(values.id, true)"
              >
                <i
                  :class="[
                    'fas',
                    storingDocument ? 'fa-spinner-third fa-spin' : 'fa-signature'
                  ]"
                />
                Start de tekensessie
              </FormulateInput>

              <template v-else>
                <FormulateForm
                  #default="{ isLoading }"
                  name="manualSigningForm"
                  @submit="storeManuallySignedDossier"
                >
                  <div class="tw-flex tw-flex-wrap tw-gap-4 tw-items-start">
                    <FormulateInput
                      type="file"
                      name="form_file"
                      label="Ondertekend document"
                      validation="bail|required|mime:application/pdf"
                      accept="application/pdf"
                      :uploader="uploadSignedDossier"
                      :file-class="['tw-h-10']"
                      :upload-area-class="['!tw-h-10 !tw-p-0']"
                      :upload-area-mask-class="['tw-h-10 !tw-rounded-sm !tw-border-0.5']"
                      element-class="formulate-input-element formulate-input-element--file tw-mt-1"
                      wrapper-class="formulate-input-wrapper"
                      outer-class="formulate-input !tw-my-0"
                    >
                      <template #file="context">
                        <BaseFileUploadDisplay
                          v-bind="context"
                          :show-remove-file="true"
                        />
                      </template>
                    </FormulateInput>
                    <FormulateInput
                      type="submit"
                      :disabled="isLoading"
                      outer-class="tw-mt-6"
                    >
                      <i
                        :class="[
                          'fas',
                          isLoading ? 'fa-spinner-third fa-spin' : 'fa-badge-check'
                        ]"
                      />
                      Indienen
                    </FormulateInput>
                    <FormulateInput
                      v-if="dossierStatus === 'awaiting_signature' || dossierStatus === 'signature_reminder_sent'"
                      type="button"
                      outer-class="tw-mt-6"
                      :input-class="['tw-bg-error tw-w-full']"
                      @click="resetDossier"
                    >
                      <i class="fas fa-times-circle" />
                      Annuleren
                    </FormulateInput>
                  </div>
                  <FormulateErrors class="tw-text-right" />
                </FormulateForm>
              </template>
            </div>
          </div>

          <!-- We don't debounce the whole form as we want the file uploads to work correctly, we only debounce the inputs that affect rendering -->
          <FormulateForm
            #default="{ isLoading }"
            v-show="activeTab === 'form'"
            v-model="values"
            key="form"
            :inert="dossierStatus !== 'not_ready_to_sign'"
            :errors="validationErrors"
            :form-errors="formErrorMessages"
            name="eccaOrderForm"
            @submit="submit"
          >
            <div class="tw-mb-8 tw-pb-4 tw-border-b">
              <h2>1. Vul hieronder de gegevens van de eigenaar in.</h2>
              <div class="tw-mx-6">
                <FormulateInput
                  type="select"
                  name="owner"
                  label="Selecteer eigenaar"
                  :options="owners"
                  :show-required-label="true"
                  outer-class="tw-my-4 tw-grid md:tw-grid-cols-2"
                  @input="ownerSelected"
                />
                <FormulateInput
                  v-show="values.owner"
                  type="group"
                  name="owner_data"
                >
                  <div class="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
                    <FormulateInput
                      v-show="ownerType === 'B'"
                      type="text"
                      name="company_name"
                      label="Bedrijfsnaam"
                      placeholder="Bedrijfsnaam"
                      disabled
                      :show-required-label="true"
                      outer-class="tw-my-0"
                    />
                    <FormulateInput
                      v-show="ownerType === 'P'"
                      type="text"
                      name="first_name"
                      label="Voornaam"
                      placeholder="Voornaam"
                      disabled
                      :show-required-label="true"
                      outer-class="tw-my-0"
                    />
                    <FormulateInput
                      v-show="ownerType === 'P'"
                      type="text"
                      name="last_name"
                      label="Achternaam"
                      placeholder="Achternaam"
                      disabled
                      :show-required-label="true"
                      outer-class="tw-my-0"
                    />
                    <FormulateInput
                      type="text"
                      name="street"
                      label="Straat"
                      placeholder="Straat"
                      disabled
                      :show-required-label="true"
                      outer-class="tw-my-0"
                    />
                    <FormulateInput
                      type="text"
                      name="number"
                      label="Nummer"
                      placeholder="Nummer"
                      disabled
                      :show-required-label="true"
                      outer-class="tw-my-0"
                    />
                    <FormulateInput
                      type="text"
                      name="zip_code"
                      label="Postcode"
                      placeholder="Postcode"
                      disabled
                      :show-required-label="true"
                      outer-class="tw-my-0"
                    />
                    <FormulateInput
                      type="text"
                      name="city"
                      label="Gemeente"
                      placeholder="Gemeente"
                      disabled
                      :show-required-label="true"
                      outer-class="tw-my-0"
                    />
                    <FormulateInput
                      type="text"
                      name="mobile"
                      label="Telefoonnummer"
                      placeholder="Telefoonnummer"
                      disabled
                      outer-class="tw-my-0"
                    />
                    <FormulateInput
                      type="text"
                      name="email"
                      label="E-mailadres"
                      placeholder="E-mailadres"
                      disabled
                      outer-class="tw-my-0"
                    />
                  </div>
                </FormulateInput>
              </div>
            </div>

            <div class="tw-my-8 tw-pb-4 tw-border-b">
              <h2>2. Zijn er gemeenschappelijke delen (mede-eigendom) of is de eigenaar een rechtspersoon? Indien ja: vul de gegevens van de VME of de desbetreffende rechtspersoon in.</h2>
              <div class="tw-mx-6">
                <FormulateInput
                  type="radio"
                  name="owner_is_legal_entity_or_property_has_common_areas"
                  :options="TRUE_FALSE"
                  :input-class="['tw-my-1']"
                  :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-items-center']"
                />
                <div
                  v-show="values.owner_is_legal_entity_or_property_has_common_areas === 'true'"
                  class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4"
                >
                  <FormulateInput
                    type="text"
                    name="organization_name"
                    label="Naam organisatie"
                    placeholder="Naam organisatie"
                    :show-required-label="true"
                    outer-class="tw-my-0"
                  />
                  <FormulateInput
                    type="text"
                    name="kbo_number"
                    label="KBO-nummer"
                    placeholder="KBO-nummer"
                    :show-required-label="true"
                    outer-class="tw-my-0"
                  />
                </div>
              </div>
            </div>

            <div class="tw-my-8 tw-pb-4 tw-border-b">
              <h2>3. Gegevens van de gevolmachtigde</h2>
              <div class="tw-mx-6">
                <h3 class="tw-font-light tw-italic tw-text-lg tw-underline">
                  Niet van toepassing
                </h3>
              </div>
            </div>

            <div class="tw-my-8 tw-pb-4 tw-border-b">
              <h2>4. Bent u gebruiker van uw te onderzoeken eigendom?</h2>
              <div class="tw-mx-6">
                <FormulateInput
                  type="radio"
                  name="owner_is_inspection_area_user"
                  :options="{
                    yes: 'Ja, bv. u woont of werkt er',
                    no: 'Nee, bv. u verhuurt de eigendom'
                  }"
                  :input-class="['tw-my-1']"
                  :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-items-center']"
                />
              </div>
            </div>

            <div class="tw-my-8 tw-pb-4 tw-border-b">
              <h2>
                5. Welk statuut hebt u als eigenaar?
                <span aria-label="required" class="input-error">
                  *
                </span>
              </h2>
              <div class="tw-mx-6">
                <FormulateInput
                  type="checkbox"
                  name="owner_statutes"
                  debounce
                  :options="ownerStatutes"
                />
                <div class="tw-grid md:tw-grid-cols-2 tw-gap-x-4">
                  <FormulateInput
                    v-show="values.owner_statutes && values.owner_statutes.find(statuteId => statuteId == ownerStatuteCompanyId)"
                    type="text"
                    name="owner_statute_sector_info"
                    label="Onderneming; sector"
                    placeholder="Onderneming; sector"
                    outer-class="tw-mb-4"
                  />
                  <FormulateInput
                    v-show="values.owner_statutes && values.owner_statutes.find(statuteId => statuteId == ownerStatuteOtherId)"
                    type="text"
                    name="owner_statute_other_info"
                    label="Andere"
                    placeholder="Andere"
                    outer-class="tw-mb-4"
                  />
                </div>
              </div>
            </div>

            <div class="tw-my-8 tw-pb-4 tw-border-b">
              <h2>6. Bepaling van de te onderzoeken eigendom </h2>

              <div class="tw-m-6">
                <h3 class="tw-font-light tw-italic tw-text-lg tw-underline">
                  Aard opdracht
                  <span aria-label="required" class="input-error">
                    *
                  </span>
                </h3>
                <p>
                  Een niet-destructief onderzoek volstaat voor een asbestattest. U kunt het onderzoek vrijwillig deels of geheel laten verruimen met destructief onderzoek. Hierbij zal de asbestdeskundige wel bv. materialen beschadigen en/of demonteren.
                  <br>
                  <br>
                  Als het onderzoek deels of geheel destructief moet zijn, duid dit dan hier aan, maar vul ook titel 9 in.
                </p>
                <FormulateInput
                  type="radio"
                  name="assignment_type"
                  info-text="Aard van de opdracht"
                  value="non_destructive"
                  :options="assignmentTypeOptions"
                  :input-class="['tw-my-1']"
                  :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-x-2 tw-items-center']"
                  :show-required-label="true"
                  outer-class="tw-m-0"
                />
              </div>

              <div class="tw-m-6">
                <h3 class="tw-font-light tw-italic tw-text-lg tw-underline">
                  Wat is de aanleiding voor het asbestattest?
                  <span aria-label="required" class="input-error">
                    *
                  </span>
                </h3>
                <FormulateInput
                  type="checkbox"
                  name="asbestos_certificate_reasons"
                  debounce
                  :options="certificateReasonOptions"
                  :show-required-label="true"
                />
                <FormulateInput
                  v-show="values.asbestos_certificate_reasons && values.asbestos_certificate_reasons.find(certificateId => certificateId == certificateReasonOtherId)"
                  type="text"
                  name="asbestos_certificate_reason_other_info"
                  label="Andere"
                  placeholder="Andere"
                  outer-class="tw-mb-4 tw-grid md:tw-grid-cols-2"
                />
              </div>

              <div class="tw-m-6">
                <h3 class="tw-font-light tw-italic tw-text-lg tw-underline">
                  Bestemming, feitelijk gebruik van het te onderzoeken eigendom
                  <span aria-label="required" class="input-error">
                    *
                  </span>
                </h3>
                <FormulateInput
                  type="radio"
                  name="property_destination"
                  debounce
                  :options="PROPERTY_DESTINATIONS"
                  :show-required-label="true"
                  :input-class="['tw-my-1']"
                  :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-x-2 tw-items-center']"
                  @input="fetchPropertyDestinationTypes"
                />
                <FormulateInput
                  v-show="destinationTypes.length"
                  type="checkbox"
                  name="property_destination_types"
                  debounce
                  :label="PROPERTY_DESTINATIONS[values.property_destination]"
                  :show-required-label="true"
                  :options="destinationTypes"
                />
                <FormulateInput
                  v-show="values.property_destination_types && values.property_destination_types.find(typeId => typeId == destinationTypeOtherId)"
                  type="text"
                  name="property_destination_type_other_info"
                  label="Andere"
                  placeholder="Andere"
                  outer-class="tw-my-4 tw-grid md:tw-grid-cols-2"
                />
              </div>

              <div class="tw-m-6">
                <h3 class="tw-font-light tw-italic tw-text-lg tw-underline">
                  Beschrijf de inspectielocatie
                </h3>
                <FormulateInput
                  v-if="values.property_data"
                  type="group"
                  name="property_data"
                >
                  <div class="tw-mt-4 tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
                    <FormulateInput
                      type="text"
                      name="street"
                      label="Straat"
                      placeholder="Straat"
                      disabled
                      :show-required-label="true"
                      outer-class="tw-my-0"
                    />
                    <FormulateInput
                      type="text"
                      name="number"
                      label="Huisnummer"
                      placeholder="Huisnummer"
                      disabled
                      :show-required-label="true"
                      outer-class="tw-my-0"
                    />
                    <FormulateInput
                      type="text"
                      name="box"
                      label="Bus"
                      placeholder="Bus"
                      disabled
                      :show-required-label="true"
                      outer-class="tw-my-0"
                    />
                    <FormulateInput
                      type="text"
                      name="zip_code"
                      label="Postcode"
                      placeholder="Postcode"
                      disabled
                      :show-required-label="true"
                      outer-class="tw-my-0"
                    />
                    <FormulateInput
                      type="text"
                      name="city"
                      label="Gemeente"
                      placeholder="Gemeente"
                      disabled
                      :show-required-label="true"
                      outer-class="tw-my-0"
                    />
                    <FormulateInput
                      type="text"
                      name="parcels"
                      label="Percelen"
                      placeholder="Percelen"
                      disabled
                      :show-required-label="true"
                      outer-class="tw-my-0"
                    />
                    <FormulateInput
                      type="text"
                      name="location_address"
                      label="Liggingsadres"
                      placeholder="Liggingsadres"
                      debounce
                      outer-class="tw-my-0"
                    />
                    <FormulateInput
                      type="text"
                      name="extra_address_details"
                      label="Extra adresdetails"
                      placeholder="Extra adresdetails"
                      debounce
                      outer-class="tw-my-0"
                    />
                  </div>
                </FormulateInput>
              </div>

              <div class="tw-m-6">
                <h3 class="tw-font-light tw-italic tw-text-lg tw-underline">
                  Meer specifieke beschrijving, indien relevant
                </h3>
                <div class="tw-my-4 tw-grid md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-4">
                  <FormulateInput
                    type="text"
                    name="building_part"
                    label="Gebouwdeel, vleugel, blok"
                    placeholder="Gebouwdeel, vleugel, blok"
                    outer-class="tw-my-0"
                  />
                  <FormulateInput
                    type="text"
                    name="floors"
                    label="Verdiepingen"
                    placeholder="Verdiepingen"
                    outer-class="tw-my-0"
                  />
                  <FormulateInput
                    type="text"
                    name="rooms"
                    label="Lokalen, ruimtes"
                    placeholder="Lokalen, ruimtes"
                    outer-class="tw-my-0"
                  />
                  <FormulateInput
                    type="text"
                    name="other_features"
                    label="Andere kenmerken"
                    placeholder="Andere kenmerken"
                    outer-class="tw-my-0"
                  />
                </div>
              </div>
            </div>

            <div class="tw-mb-8 tw-pb-4 tw-border-b">
              <h2>7. Moeten er gemeenschappelijke delen worden onderzocht (mede-eigendom)?</h2>
              <div class="tw-mx-6">
                <FormulateInput
                  type="radio"
                  name="should_inspect_common_parts"
                  :options="YES_NO"
                  value="no"
                  :input-class="['tw-my-1']"
                  :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-x-2 tw-items-center']"
                />
              </div>
            </div>

            <div class="tw-mb-8 tw-pb-4 tw-border-b">
              <h2>8. Eigendomsinformatie en bewijsdocumenten</h2>
              <div class="tw-mx-6">
                <FormulateInput
                  type="group"
                  name="years"
                >
                  <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-max-w-[50%]">
                    <FormulateInput
                      type="number"
                      name="build_year"
                      label="Bouwjaar"
                      placeholder="Bouwjaar"
                      :show-required-label="true"
                    />
                    <FormulateInput
                      type="number"
                      name="renovation_year"
                      label="Renovatiejaar"
                      placeholder="Renovatiejaar"
                    />
                  </div>
                </FormulateInput>

                <FormulateInput
                  type="radio"
                  name="inspection_location_has_protected_status"
                  label="Heeft de inspectielocatie een beschermd statuut?"
                  :options="{
                    no: 'Nee',
                    yes: 'Ja: voeg aan dit opdrachtformulier informatie toe over welke gebouwdelen precies beschermd erfgoed zijn, indien voorhanden'
                  }"
                  debounce
                  :input-class="['tw-my-1']"
                  :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-items-center']"
                />
                <p v-if="values.inspection_location_has_protected_status === 'yes'">
                  Opgelet! Voor het plaatsbezoek van de asbestdeskundige (monstername, opheffen of demonteren materialen) of voor een asbestverwijdering achteraf, is mogelijk een toelating of melding nodig. Neem hiervoor contact op met het agentschap Onroerend Erfgoed om na te gaan of dit het geval is. Mail hiervoor naar info@onroerenderfgoed.be of bel naar 02 553 16 50.
                </p>

                <FormulateInput
                  v-if="values.inspection_location_has_protected_status === 'yes'"
                  type="radio"
                  name="obligation_for_sampling_exists"
                  label="Is de toelatingsplicht of meldingsplicht van toepassing voor de monsternames?"
                  :options="{
                    no: 'Nee',
                    yes: 'Ja : voeg deze toelating of melding toe aan het opdrachtformulier.'
                  }"
                  debounce
                  :input-class="['tw-my-1']"
                  :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-items-center']"
                />

                <FormulateInput
                  type="group"
                  name="media_objects"
                  outer-class="tw-my-8"
                >
                  <p>Voeg volgende documenten, indien relevant en indien voorhanden, als bijlagen toe bij het opdrachtformulier en benoem ze ondubbelzinnig en afzonderlijk. Duid aan wat van toepassing is:</p>
                  <div class="tw-mt-4 tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
                    <!-- We need to abuse some margins in order to maintain consistent position for the upload and file area, across all inputs -->
                    <FormulateInput
                      type="file"
                      multiple
                      name="floor_plan"
                      label="Grondplan per gebouw per verdieping"
                      :uploader="uploadFile"
                      add-label="+ Bestand toevoegen"
                      :upload-area-mask-class="['tw-h-10 !tw-rounded-sm !tw-border-0.5']"
                      :file-class="['tw-h-10']"
                      element-class="formulate-input-element formulate-input-element--file tw-mt-1 xl:tw-mt-6"
                      wrapper-class="formulate-input-wrapper"
                      outer-class="formulate-input"
                    >
                      <template #file="context">
                        <BaseFileUploadDisplay
                          v-bind="context"
                          :show-remove-file="true"
                        />
                      </template>
                    </FormulateInput>
                    <FormulateInput
                      type="file"
                      multiple
                      name="plan_with_marked_buildings"
                      label="Plan met aanduiding van de te onderzoeken gebouwen"
                      :uploader="uploadFile"
                      add-label="+ Bestand toevoegen"
                      :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
                      :file-class="['tw-h-10 !tw-mt-1']"
                      element-class="formulate-input-element formulate-input-element--file"
                      wrapper-class="formulate-input-wrapper"
                      outer-class="formulate-input"
                    >
                      <template #file="context">
                        <BaseFileUploadDisplay
                          v-bind="context"
                          :show-remove-file="true"
                        />
                      </template>
                    </FormulateInput>
                    <FormulateInput
                      type="image"
                      multiple
                      name="situational_photo"
                      label="Situatiefoto's per type lokaal"
                      :uploader="uploadImage"
                      add-label="+ Voeg afbeelding toe"
                      accept="image/png,image/gif,image/jpeg"
                      :upload-area-mask-class="['tw-h-10 !tw-rounded-sm !tw-border-0.5']"
                      :file-class="['tw-h-10']"
                      element-class="formulate-input-element formulate-input-element--file tw-mt-1 lg:tw-mt-6"
                      wrapper-class="formulate-input-wrapper"
                      outer-class="formulate-input"
                    >
                      <template #file="context">
                        <BaseFileUploadDisplay
                          v-bind="context"
                          :is-image="true"
                          :show-remove-file="true"
                        />
                      </template>
                    </FormulateInput>
                    <FormulateInput
                      type="file"
                      multiple
                      name="existing_asbestos_survey"
                      label="Bestaand asbestonderzoek"
                      :uploader="uploadFile"
                      add-label="+ Bestand toevoegen"
                      :upload-area-mask-class="['tw-h-10 !tw-rounded-sm !tw-border-0.5']"
                      :file-class="['tw-h-10']"
                      element-class="formulate-input-element formulate-input-element--file tw-mt-1 lg:tw-mt-6"
                      wrapper-class="formulate-input-wrapper"
                      outer-class="formulate-input"
                    >
                      <template #file="context">
                        <BaseFileUploadDisplay
                          v-bind="context"
                          :show-remove-file="true"
                        />
                      </template>
                    </FormulateInput>
                    <FormulateInput
                      type="file"
                      multiple
                      name="past_asbestos_removal_works_document"
                      label="Document in het kader van asbestverwijderingswerken in het verleden"
                      :uploader="uploadFile"
                      add-label="+ Bestand toevoegen"
                      :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
                      :file-class="['tw-h-10 !tw-mt-1']"
                      element-class="formulate-input-element formulate-input-element--file"
                      wrapper-class="formulate-input-wrapper"
                      outer-class="formulate-input"
                    >
                      <template #file="context">
                        <BaseFileUploadDisplay
                          v-bind="context"
                          :show-remove-file="true"
                        />
                      </template>
                    </FormulateInput>
                    <FormulateInput
                      type="file"
                      multiple
                      name="past_construction_demolition_or_renovation_work_document"
                      label="Document in het kader van bouw-, afbraak-, en/of renovatiewerken in het verleden"
                      :uploader="uploadFile"
                      add-label="+ Bestand toevoegen"
                      :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
                      :file-class="['tw-h-10 !tw-mt-1']"
                      element-class="formulate-input-element formulate-input-element--file"
                      wrapper-class="formulate-input-wrapper"
                      outer-class="formulate-input"
                    >
                      <template #file="context">
                        <BaseFileUploadDisplay
                          v-bind="context"
                          :show-remove-file="true"
                        />
                      </template>
                    </FormulateInput>
                    <FormulateInput
                      v-show="values.inspection_location_has_protected_status === 'yes'"
                      type="file"
                      multiple
                      name="protected_heritage_status_document"
                      label="Document aangaande beschermd erfgoedstatuut"
                      :uploader="uploadFile"
                      add-label="+ Bestand toevoegen"
                      :upload-area-mask-class="['tw-h-10 !tw-rounded-sm !tw-border-0.5']"
                      :file-class="['tw-h-10']"
                      element-class="formulate-input-element formulate-input-element--file tw-mt-1 lg:tw-mt-6"
                      wrapper-class="formulate-input-wrapper"
                      outer-class="formulate-input"
                    >
                      <template #file="context">
                        <BaseFileUploadDisplay
                          v-bind="context"
                          :show-remove-file="true"
                        />
                      </template>
                    </FormulateInput>
                    <FormulateInput
                      type="file"
                      multiple
                      name="industrial_installations_document"
                      label="Documenten aangaande industriële installaties"
                      :uploader="uploadFile"
                      add-label="+ Bestand toevoegen"
                      :upload-area-mask-class="['tw-h-10 !tw-rounded-sm !tw-border-0.5']"
                      :file-class="['tw-h-10']"
                      element-class="formulate-input-element formulate-input-element--file tw-mt-1 lg:tw-mt-6"
                      wrapper-class="formulate-input-wrapper"
                      outer-class="formulate-input"
                    >
                      <template #file="context">
                        <BaseFileUploadDisplay
                          v-bind="context"
                          :show-remove-file="true"
                        />
                      </template>
                    </FormulateInput>
                    <FormulateInput
                      v-show="values.obligation_for_sampling_exists === 'yes'"
                      type="file"
                      multiple
                      name="authorization_for_sampling_document"
                      label="Toelating/melding voor monsternames"
                      :uploader="uploadFile"
                      add-label="+ Bestand toevoegen"
                      :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
                      :file-class="['tw-h-10']"
                      element-class="formulate-input-element formulate-input-element--file"
                      wrapper-class="formulate-input-wrapper"
                      outer-class="formulate-input"
                    >
                      <template #file="context">
                        <BaseFileUploadDisplay
                          v-bind="context"
                          :show-remove-file="true"
                        />
                      </template>
                    </FormulateInput>
                  </div>
                </FormulateInput>

                <FormulateInput
                  type="radio"
                  name="aware_of_enclosed_or_covered_asbestos_materials"
                  label="Bent u op de hoogte van de aanwezigheid van ingesloten of bedekte asbesthoudende materialen in uw eigendom?"
                  debounce
                  :options="YES_NO"
                  :input-class="['tw-my-1']"
                  :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-items-center']"
                />
                <FormulateInput
                  v-show="values.aware_of_enclosed_or_covered_asbestos_materials === 'yes'"
                  type="text"
                  name="aware_of_enclosed_or_covered_asbestos_materials_info"
                  label="Meer informatie"
                  placeholder="Meer informatie"
                  outer-class="tw-my-4 tw-grid md:tw-grid-cols-2"
                />
              </div>
            </div>

            <div class="tw-mb-8 tw-pb-4 tw-border-b">
              <h2>9. Vrijwillig, aanvullend onderzoek - OPTIONEEL</h2>
              <div class="tw-mx-6">
                <FormulateInput
                  type="radio"
                  name="include_additional_research"
                  debounce
                  :options="TRUE_FALSE"
                  :input-class="['tw-my-1']"
                  :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-items-center']"
                />

                <FormulateInput
                  v-show="values.include_additional_research === 'true'"
                  type="group"
                  name="additional_research"
                  outer-class="tw-mt-8"
                >
                  <template #default="{ model }">
                    <FormulateInput
                      type="checkbox"
                      name="research_reasons"
                      label="Wenst u in de context van de federale arbeidswetgeving (verplicht als werkgever) of om andere redenen, de opdracht te verruimen? Duid dan aan wat van toepassing is:"
                      :show-required-label="true"
                      debounce
                      :options="researchReasonsOptions"
                    />

                    <div class="tw-my-8">
                      <label class="formulate-label">
                        Wenst u naar aanleiding van geplande werken of om andere redenen, de opdracht te verruimen met destructief onderzoek? Beschrijf dan ondubbelzinnig voor welke delen u dit wenst:
                      </label>
                      <div class="tw-mt-4 tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
                        <FormulateInput
                          type="text"
                          name="building_info"
                          label="Gebouw"
                          placeholder="Gebouw"
                          :show-required-label="true"
                          outer-class="tw-my-0"
                        />
                        <FormulateInput
                          type="text"
                          name="building_part_info"
                          label="Gebouwdeel"
                          placeholder="Gebouwdeel"
                          outer-class="tw-my-0"
                        />
                        <FormulateInput
                          type="text"
                          name="floor_info"
                          label="Verdieping"
                          placeholder="Verdieping"
                          outer-class="tw-my-0"
                        />
                        <FormulateInput
                          type="text"
                          name="room_and_room_function_info"
                          label="Lokaal en functie lokaal"
                          placeholder="Lokaal en functie lokaal"
                          outer-class="tw-my-0"
                        />
                      </div>
                    </div>

                    <FormulateInput
                      type="checkbox"
                      name="areas_destr_research"
                      label="Toepassing die destructief onderzocht mag worden"
                      :show-required-label="true"
                      debounce
                      :options="areasForDestructiveResearchOptions"
                    />
                    <FormulateInput
                      v-show="
                        model[0] &&
                        model[0].areas_destr_research &&
                        model[0].areas_destr_research.find(areaId => areaId == destructiveResearchOtherAreaId)
                      "
                      type="text"
                      name="areas_for_destructive_research_other_info"
                      label="Andere"
                      placeholder="Toepassing die destructief onderzocht mag worden: andere"
                      outer-class="tw-my-4 tw-grid md:tw-grid-cols-2"
                    />
                    <p>Monstername waarbij er schade kan ontstaan die het normale gebruik van het eigendom niet meer kan garanderen, behoort ook tot destructief onderzoek. Bijvoorbeeld het nemen van een monster van roofing op een plat dak, waarbij er een kans is op lekkages.</p>
                  </template>
                </FormulateInput>
              </div>
            </div>

            <div class="tw-mb-8">
              <h2>10. Actualisatie van een bestaand asbestattest - INDIEN VAN TOEPASSING</h2>
              <div class="tw-mx-6">
                <FormulateInput
                  type="radio"
                  name="is_asbestos_certificate_update"
                  debounce
                  :options="TRUE_FALSE"
                  :input-class="['tw-my-1']"
                  :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-items-center']"
                />
                <div v-show="values.is_asbestos_certificate_update === 'true'">
                  <FormulateInput
                    type="text"
                    name="numbers_of_existing_asbestos_certificates"
                    label="Nummer(s) bestaand(e) asbestattest(en)"
                    placeholder="Nummer(s) bestaand(e) asbestattest(en)"
                    :show-required-label="true"
                    outer-class="tw-my-4 tw-grid md:tw-grid-cols-2"
                  />
                  <FormulateInput
                    type="checkbox"
                    name="update_reasons"
                    debounce
                    :show-required-label="true"
                    :options="updateReasonsOptions"
                  />
                  <FormulateInput
                    v-show="values.update_reasons && values.update_reasons.find(reasonId => reasonId == updateReasonOtherId)"
                    type="text"
                    name="update_reasons_other_info"
                    label="Andere"
                    placeholder="Andere"
                    outer-class="tw-my-4 tw-grid md:tw-grid-cols-2"
                  />
                </div>
              </div>
            </div>

            <FormulateErrors class="tw-text-right" />
            <div
              v-if="dossierStatus === 'not_ready_to_sign'"
              class="tw-my-4 tw-flex tw-justify-end tw-gap-4 tw-flex-wrap tw-items-end"
            >
              <FormulateInput
                type="select"
                name="signature_method"
                label="Handtekening methode"
                :options="{
                  digital: 'Digitaal',
                  manual: 'Manueel'
                }"
                value="digital"
                outer-class="tw-my-0"
              />
              <FormulateInput
                type="submit"
                :disabled="isLoading"
                :input-class="['tw-bg-gray-500']"
                outer-class="tw-my-0"
              >
                <i
                  :class="[
                    'fas tw-mr-2',
                    !generateDocument && isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
                  ]"
                />
                Opslaan
              </FormulateInput>

              <FormulateInput
                type="submit"
                :disabled="isLoading"
                outer-class="tw-my-0"
                @click="generateDocument = true"
              >
                <i
                  :class="[
                    'fas tw-mr-2',
                    generateDocument && isLoading ? 'fa-spinner-third fa-spin' : 'fa-bolt'
                  ]"
                />
                Genereren
              </FormulateInput>
            </div>
          </FormulateForm>
        </transition-group>
      </div>
    </BaseModal>

    <SignatureModal
      ref="signatureModal"
      document-type="asbestos"
      :load-data="false"
      :property="property"
      @package-id-generated="updateDossierStatus"
      @live-session-started="pollLiveSigningSession"
    />
  </div>
</template>

<script>
import { Tooltip } from 'floating-vue'
import { successModal, errorModal, startLoadingModal, stopLoadingModal, questionModal } from '@/modalMessages'
import { poll, parseFileObj } from '@/utils/helpers'

import SignatureModal from '@/components/SignatureModal'
import EccaAssignmentForm from '@/components/orders/EccaAssignmentForm'

import {
  placeV2Order,
  getAsbestosDossiers,
  updateAsbestosDossier,
  getDestinationTypes,
  getDossierMediaObjects,
  createDossierMediaObject,
  updateDossierMediaObjects,
  getOwnerStatutesForAsbestos,
  getAreasForDestructiveResearch,
  getAsbestosUpdateReasons,
  getAsbestosCertificateReasons,
  getAsbestosResearchReasons,
  validateAsbestosDossier,
  generateAsbestosDocument,
  storeAsbestosFormFile,
  pollSignatureStatus,
  getAsbestosDossierHistory,
  resetAsbestosDossier
} from '@/services/orders'

import {
  getPropertyOwners,
  uploadFile,
  uploadPropertyPicture,
  createPropertyFile,
  createPropertyFileFolder,
  getPropertyFileFolders,
  updatePropertyTechnical,
  storeGutenborgFile,
  getPropertyFileById
} from '@/services/properties'

import { pollGutenborg } from '@/services/gutenborgService'

export default {
  name: 'EccaOrderModal',
  components: {
    Tooltip,
    SignatureModal,
    EccaAssignmentForm
  },
  props: {
    property: {
      type: Object,
      required: true
    },
    todo: {
      type: Object,
      required: true
    }
  },
  constants: {
    ECCA_SUPPLIER_KEY: 'ecca',
    ECCA_PRODUCT_KEY: 'ecca_asbestos_inventory',
    PROPERTY_DESTINATIONS: {
      public_construction: 'Publieke constructie',
      non_residential: 'Niet-residentieel gebruik',
      residential: 'Residentieel gebruik'
    },
    YES_NO: {
      yes: 'Ja',
      no: 'Nee'
    },
    TRUE_FALSE: {
      // These fields are stored as Boolean on the BE, but to maintain consistency on the FE, we use 'Ja'/'Nee' still
      true: 'Ja',
      false: 'Nee'
    }
  },
  data () {
    return {
      activeTab: 'form',
      values: {
        owner_data: []
      },
      owners: [],
      ownerStatutes: [],
      destinationTypes: [],
      destinationTypeOtherId: null,
      areasForDestructiveResearchOptions: [],
      updateReasonsOptions: [],
      folderId: null, // folder id for the folder called Asbest
      ownerStatuteOtherId: null,
      ownerStatuteCompanyId: null,
      updateReasonOtherId: null,
      destructiveResearchOtherAreaId: null,
      areasForDestructiveResearch: [],
      certificateReasonOptions: [],
      certificateReasonOtherId: null,
      researchReasonsOptions: [],
      formErrorMessages: [],
      validationErrors: {},
      generateDocument: false,
      pdfUrl: null,
      storingDocument: false,
      dossierStatus: 'not_ready_to_sign',
      statusDisplay: null,
      assignmentStatus: null,
      generatedNewFile: false,
      dossierHistory: null
    }
  },
  computed: {
    title () {
      const titles = {
        ready_to_send: 'Asbestdossier klaarzetten',
        in_progress: 'Asbestdossier'
      }
      const title = [titles[this.dossierStatus] || 'Opdrachtformulier opmaak van een asbestinventaris voor een asbestattest']
      if (this.assignmentStatus) title.push(`(${this.assignmentStatus})`.toLowerCase())
      else if (this.statusDisplay) title.push(`(${this.statusDisplay})`.toLowerCase())
      return title.filter(title => title).join(' ')
    },
    ownerType () {
      if (!this.values.owner) return null
      return this.owners.find(owner => parseInt(owner.id) === parseInt(this.values.owner))?.type
    },
    assignmentTypeOptions () {
      return [
        { value: 'non_destructive', label: 'Volledig niet-destructief (voldoende voor asbestattest)' },
        { value: 'destructive', label: 'Volledig destructief', disabled: true },
        { value: 'partial', label: 'Deels niet-destructief en deels destructief', disabled: true }
      ]
    }
  },
  methods: {
    ownerSelected (value) {
      const owner = this.owners.find(owner => parseInt(owner.value) === parseInt(value))
      this.$set(this.values, 'owner_data', [owner])
      return owner
    },
    showSignatureModal (response) {
      // Merge is required, as mentioned in this ticket: https://dewaele.atlassian.net/browse/DEW-9379
      this.$refs.signatureModal.documents = [{ merge: true, ...response.data }]

      // owner_data doesn't contain all the fields we need, so we use this method
      const owner = this.owners.find(owner => parseInt(owner.value) === parseInt(this.values.owner))
      this.$refs.signatureModal.owners = [owner]

      this.$refs.signatureModal.show()
    },

    dossierResetted () {
      // It is not a full reset, it just means that we are back to step 1.
      this.pdfUrl = null
      this.activeTab = 'form'
      this.statusDisplay = null
      this.assignmentStatus = null
      this.validationErrors = {}
      this.formErrorMessages = []
      this.dossierStatus = 'not_ready_to_sign'
      this.$set(this.values, 'form_file', null)
      this.$set(this.values, 'unsigned_form_file', null)
    },

    onHide () {
      this.values = {}
      this.pdfUrl = null
      this.activeTab = 'form'
      this.dossierStatus = 'not_ready_to_sign'
      this.statusDisplay = null
      this.assignmentStatus = null
      this.formErrorMessages = []
      this.validationErrors = {}
      this.dossierHistory = null
    },
    hide () {
      this.$refs.modal.hide()
    },

    markTheDossierReadyToSend (dossierId) {
      this.dossierStatus = 'ready_to_send'
      this.activeTab = 'send'
      return this.fetchDossierHistory(dossierId)
    },

    async show () {
      const response = await this.init()
      this.$refs.modal.show()
      return response
    },
    async init () {
      await Promise.all([
        this.fetchOwners(),
        this.fetchOwnerStatutes(),
        this.fetchAsbestosUpdateReasons(),
        this.fetchAsbestosResearchReasons(),
        this.fetchAsbestosCertificateReasons(),
        this.fetchAreasForDestructiveResearch()
      ])
      // Let all the options be loaded, before we bring in the data
      const response = await this.fetchAsbestosDossiers()
      return response
    },

    async fetchOwners () {
      const response = await getPropertyOwners(this.property.id)
      this.owners = response.data?.map(owner => {
        return { label: owner.display_name, value: owner.id, ...owner }
      })
      return response
    },
    async fetchOwnerStatutes () {
      const response = await getOwnerStatutesForAsbestos()
      this.ownerStatutes = response.data?.results?.map(({ name, key, id }) => {
        if (key === 'other') this.ownerStatuteOtherId = id
        if (key === 'company') this.ownerStatuteCompanyId = id
        return { label: name, value: id, key }
      })
      return response
    },

    async fetchPropertyDestinationTypes (destination) {
      // Fetch the destination types after selecting a particulat property destination.
      if (!destination) return

      this.destinationTypes = []
      const response = await getDestinationTypes({ destination })
      this.destinationTypes = response.data?.results?.map(({ name, id, key }) => {
        if (key === 'other') this.destinationTypeOtherId = id
        return { label: name, value: id, key }
      })
      return response
    },
    async fetchAreasForDestructiveResearch () {
      const response = await getAreasForDestructiveResearch()
      this.areasForDestructiveResearchOptions = response.data?.results?.map(({ name, key, id }) => {
        if (key === 'other') this.destructiveResearchOtherAreaId = id
        return { label: name, value: id, key }
      })
      return response
    },
    async fetchAsbestosUpdateReasons () {
      const response = await getAsbestosUpdateReasons()
      this.updateReasonsOptions = response.data?.results?.map(({ name, key, id }) => {
        if (key === 'other') this.updateReasonOtherId = id
        return { label: name, value: id, key }
      })
      return response
    },
    async fetchAsbestosCertificateReasons () {
      const response = await getAsbestosCertificateReasons()
      this.certificateReasonOptions = response.data?.results?.map(({ name, key, id }) => {
        if (key === 'transfer') this.defaultCertificateReasons = [{ label: name, value: id, key }]
        if (key === 'other') this.certificateReasonOtherId = id
        return { label: name, value: id, key }
      })
      return response
    },
    async fetchAsbestosResearchReasons () {
      const response = await getAsbestosResearchReasons()
      this.researchReasonsOptions = response.data?.results?.map(({ name, key, id }) => {
        return { label: name, value: id, key }
      })
      return response
    },

    async fetchAsbestosDossiers () {
      const { street, box, number, city, build_year, parcels, renovation_year } = this.property
      const defaultPropertyData = {
        box,
        number,
        street,
        parcels,
        zip_code: city.zip_code,
        city: city.name
      }
      const response = await getAsbestosDossiers({ todo_id: this.todo.id })
      // Each todo can have only one dossier
      const {
        status,
        status_display,
        assignment_status,
        unsigned_form_file,
        additional_research,
        property_destination,
        owner = this.owners[0],
        owner_data = {},
        property_data = {},
        // True/False fields
        include_additional_research = false,
        is_asbestos_certificate_update = false,
        owner_is_legal_entity_or_property_has_common_areas = false,
        asbestos_certificate_reasons = ['1'],
        ...dossier
      } = response.data?.results?.[0] || {}

      this.values = {
        owner: owner.id,
        todo: this.todo.id,
        property_destination,
        property: this.property.id,
        owner_data: Object.keys(owner_data).length !== 0 ? [{ ...owner_data }] : [{ ...owner }],
        property_data: [{ ...property_data, ...defaultPropertyData }],
        years: [{ build_year, renovation_year }],
        additional_research: [additional_research || {}],
        asbestos_certificate_reasons: asbestos_certificate_reasons.length ? asbestos_certificate_reasons : ['1'],

        // Convert True/False fields to strings
        include_additional_research: include_additional_research.toString(),
        is_asbestos_certificate_update: is_asbestos_certificate_update.toString(),
        owner_is_legal_entity_or_property_has_common_areas: owner_is_legal_entity_or_property_has_common_areas.toString(),
        ...dossier
      }

      if (status) this.dossierStatus = status
      if (status_display) this.statusDisplay = status_display
      if (assignment_status?.status_display) this.assignmentStatus = assignment_status?.status_display

      // If the document was already signed, no reason to show the unsigned file anymore,
      // user can see the signed documents in the assignment tab.
      if (status === 'ready_to_send') this.activeTab = 'send'
      else if (unsigned_form_file) {
        this.pdfUrl = unsigned_form_file.url
        this.activeTab = 'preview'
        this.$set(this.values, 'unsigned_form_file', unsigned_form_file.id)
        this.generatedNewFile = false
      }

      if (dossier.id) {
        await Promise.all([
          this.fetchDossierHistory(dossier.id),
          this.fetchDossierMediaObjects(dossier.id)
        ])
      }
      return response
    },

    async fetchDossierHistory (dossierId) {
      const response = await getAsbestosDossierHistory(dossierId)
      this.dossierHistory = response.data?.history
      return response
    },

    async fetchDossierMediaObjects (dossierId) {
      const response = await getDossierMediaObjects(dossierId)
      this.values = {
        ...this.values,
        media_objects: [response.data]
      }
      return response
    },

    async fetchOrCreateAsbestFolder () {
      const response = await getPropertyFileFolders(this.property.id, { query: 'Asbest' })
      this.folderId = response?.data?.results?.[0]?.id

      if (!this.folderId) {
        const response = await createPropertyFileFolder(this.property.id, { name: 'Asbest' })
        this.folderId = response.data.id
      }

      // We don't want to call this method again and again, so we store the value of the folderId
      return this.folderId
    },

    async uploadSignedDossier (file, progress) {
      const file_type_key = 'asbestos_form_signed'
      return await this.uploadFileApi(file, progress, file_type_key)
    },
    async uploadFile (file, progress) {
      const file_type_key = 'asbestos_attachment'
      return await this.uploadFileApi(file, progress, file_type_key)
    },
    async uploadFileApi (file, progress, file_type_key) {
      try {
        progress(0)
        const formData = new FormData()
        formData.append('file', file)
        progress(25)
        const { key, filename } = (await uploadFile(this.property.id, formData)).data
        progress(50)
        const folder_id = this.folderId || await this.fetchOrCreateAsbestFolder()
        progress(75)
        const payload = { key, filename, file_type_key, folder_id }
        const response = await createPropertyFile(this.property.id, payload)
        progress(100)

        const parsedFile = parseFileObj(response.data)
        return [parsedFile]
      } catch (error) {
        this.$formulate.handle(error, 'eccaOrderForm')
      }
    },
    async uploadImage (file, progress) {
      try {
        progress(0)
        const formData = new FormData()
        formData.append('file', file)
        progress(40)
        const response = await uploadPropertyPicture(this.property.id, formData)
        progress(100)

        const parsedFile = parseFileObj(response.data)
        return [parsedFile]
      } catch (error) {
        this.$formulate.handle(error, 'eccaOrderForm')
      }
    },

    async createDossierMediaObject (dossierId, payload) {
      const response = await createDossierMediaObject(dossierId, payload)
      return response
    },

    async submit (values) {
      try {
        // Reset the errors generated by the previous validation API call
        this.$formulate.resetValidation('eccaOrderForm')
        this.formErrorMessages = []
        this.validationErrors = {}

        // We don't need to send id, uuid, years, media_objects, or owner_data in the payload.
        const {
          id,
          uuid,
          years,
          owner_data,
          property_data,
          media_objects,
          additional_research,
          // True/False fields
          include_additional_research,
          is_asbestos_certificate_update,
          owner_is_legal_entity_or_property_has_common_areas,
          ...restOfTheFields
        } = values

        // We don't need the complete property_data object, but we do need these two fields
        const { extra_address_details, location_address } = property_data[0]

        const data = {
          // We place the rest of the fields at the top so that they don't override any of the bottom fields by any chance.
          ...restOfTheFields,

          location_address,
          extra_address_details,

          // Because it's a formulate group and is returned as an array
          additional_research: additional_research[0],

          // If string is true, then value assigned would be Boolean true, if not, then it will be Boolean false.
          include_additional_research: include_additional_research === 'true',
          is_asbestos_certificate_update: is_asbestos_certificate_update === 'true',
          owner_is_legal_entity_or_property_has_common_areas: owner_is_legal_entity_or_property_has_common_areas === 'true'
        }

        const property_id = this.property.id
        const payload = {
          // Product ids and supplier id are default values taken from the ticket: https://dewaele.atlassian.net/browse/DEW-8170
          data,
          property_id,
          product_keys: [this.ECCA_PRODUCT_KEY],
          todo_id: this.todo.id,
          supplier_key: this.ECCA_SUPPLIER_KEY
        }

        const [response] = await Promise.all([
          id ? updateAsbestosDossier(id, data) : placeV2Order(payload),
          updatePropertyTechnical(property_id, years[0])
        ])

        const dossierId = id || response.data?.data?.asbestos_dossier_id
        this.$set(this.values, 'id', dossierId)
        const media = []

        if (media_objects) {
          for (const media_type in media_objects[0]) {
          // Vue formulate groups are arrays, even if it's not repeatable. So we have to take the 0th index item to get our values.
            const files = media_objects[0][media_type]?.files
            media.push(
            files?.map(file => {
              // The first item of the file path array contains the details we need.
              // In case of upload, path is an array.
              // In case of saved data, path is an object
              const { id, property_file, property_picture } = file.path[0] || file.path
              return {
                media_type,
                // Either it is a property file, property picture, or a newly uploaded file.
                [media_type === 'situational_photo'
                  ? 'property_picture_id'
                  : 'property_file_id'
                ]: property_picture || property_file || id
              }
            })
            )
          }
          await updateDossierMediaObjects(dossierId, { media: media.flat() })
        }

        if (this.generateDocument) return await this.validateAndGenerateDocument(dossierId)
        await this.fetchDossierHistory(dossierId)
        successModal('Veranderingen succesvol opgeslagen')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'eccaOrderForm')
        throw error
      } finally {
        this.generateDocument = false // reset the generate document condition
      }
    },

    async validateAndGenerateDocument (dossierId) {
      const validation = await validateAsbestosDossier(dossierId)
      const {
        valid,
        messages,
        property_data,
        additional_research,
        owner_data,
        ...errors
      } = validation.data

      const { build_year, renovation_year } = property_data
      const years = { build_year, renovation_year } // Because we use years group
      const groups = { years, property_data, additional_research, owner_data }

      for (const label in groups) {
        const group = groups[label]
        for (const property in group) {
          errors[`${label}.0.${property}`] = group[property]
        }
      }

      this.formErrorMessages = messages
      this.validationErrors = errors
      if (!valid) return

      const generate = await generateAsbestosDocument(dossierId)
      const { url } = await poll(generate?.data?.document_id, pollGutenborg, 1000)

      if (url) {
        this.pdfUrl = url
        this.activeTab = 'preview'
        this.generatedNewFile = true
        // Clear the previous unsigned file
        this.$set(this.values, 'unsigned_form_file', null)
        // We store the file on generating only when it is manual signature
        if (this.values.signature_method === 'manual') await this.storeGeneratedDocument(dossierId)
      }
      return generate
    },

    async storeGeneratedDocument (asbestos_dossier_id, digitalSignature = false) {
      try {
        this.storingDocument = true
        // IF GETTING AN ERROR ABOUT CopyObject -> Set GUTENBORG_CACHE_BUCKET to gutenborg-cache-qa in BE
        if (this.values.unsigned_form_file && digitalSignature && !this.generatedNewFile) {
          const response = await getPropertyFileById(this.property.id, this.values.unsigned_form_file)
          return this.showSignatureModal(response)
        }
        const payload = {
          url: this.pdfUrl,
          type: 'asbestos_form',
          meta: { asbestos_dossier_id }
        }

        const response = await storeGutenborgFile(this.property.id, payload)
        this.$set(this.values, 'unsigned_form_file', response.data.id)
        successModal('Het bestand is succesvol opgeslagen')
        this.generatedNewFile = false

        if (digitalSignature) this.showSignatureModal(response)
        return response
      } catch (error) {
        errorModal('Kan het bestand niet opslaan, probeer het opnieuw.')
        throw error
      } finally {
        this.storingDocument = false
      }
    },

    async updateDossierStatus (packageId) {
      // When the document is sent for signing, we update the status of the dossier accordingly.
      const status = 'awaiting_signature'
      const response = await updateAsbestosDossier(this.values.id, {
        status,
        package: packageId

      })
      this.dossierStatus = status
      await this.fetchDossierHistory(this.values.id)
      return response
    },

    async storeManuallySignedDossier (data) {
      try {
        const { form_file } = data
        const form_file_id = form_file[0]?.[0]?.id

        const response = await storeAsbestosFormFile(this.values.id, { form_file_id })
        await this.markTheDossierReadyToSend(this.values.id)
        return response
      } catch (error) {
        this.$formulate.handle(error, 'manualSigningForm')
        throw error
      }
    },

    async pollLiveSigningSession () {
      try {
        startLoadingModal('De status van het pakket wordt opgehaald, sluit dit scherm niet.')

        const response = await new Promise((resolve, reject) => {
          const poll = window.setInterval(async () => {
            try {
              const response = await pollSignatureStatus(this.values.id)
              const status = response.data.signature_status

              if (status === 'Finished') {
                successModal('Pakket succesvol ondertekend.')
                await this.markTheDossierReadyToSend(this.values.id)
                clearInterval(poll)
                resolve(status)
              } else if (status === 'Revoked') {
                errorModal('Pakket niet ondertekend')
                clearInterval(poll)
                resolve(status)
              }
            } catch (error) {
              clearInterval(poll)
              reject(error)
            }
          }, 10000)
        })

        return response
      } catch (error) {
        errorModal('Kan de status niet ophalen, controleer dit later.')
        throw error
      } finally {
        stopLoadingModal()
      }
    },
    async resetDossier () {
      const result = await questionModal('Door deze handeling wordt het dossier gereset naar de opbouw fase. Weet u zeker dat u dit wilt doen?')
      if (!result.value) return
      const response = await resetAsbestosDossier(this.values.id)
      this.hide()
      return response
    }
  }
}
</script>
